
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import * as T from 'components/ui/typography';
import Button from 'components/shared/button';
import AppLogo from 'components/shared/logo';
import { useRouter } from 'next/router';
import Routes from 'utils/routes';
import Head from 'next/head';
const NotFound = (): JSX.Element => {
    const router = useRouter();
    return (<div className="flex flex-col h-screen items-center justify-center gap-10">
            <Head>
                <title>404</title>
            </Head>
            <AppLogo hideName/>
            <T.H3>{"Ops, Qua non c'è nulla"}</T.H3>
            <Button onClick={() => {
            router.push(Routes.Home);
        }}>
                Torna alla HOME
            </Button>
        </div>);
};
export default NotFound;

    async function __Next_Translate__getStaticProps__18d463396df__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18d463396df__ as getStaticProps }
  